import React, { useEffect, useState } from 'react'
import Carousel from 'react-multi-carousel';
import 'react-multi-carousel/lib/styles.css';
import { Link } from "react-router-dom";
import { getSuggestionProducts } from '../../services/Services';
import { AiOutlineHeart, AiFillHeart } from 'react-icons/ai';
import './SuggestElements.css';

const responsive = {
    superLargeDesktop: {
        // the naming can be any, depends on you.
        breakpoint: { max: 4000, min: 3000 },
        items: 5
    },
    desktop: {
        breakpoint: { max: 3000, min: 1024 },
        items: 4
    },
    tablet: {
        breakpoint: { max: 1024, min: 464 },
        items: 3
    },
    mobile: {
        breakpoint: { max: 464, min: 0 },
        items: 2
    }
};

const SuggestElements = () => {
    const [data, setData] = useState([]);
    const [wishlist, setWishlist] = useState(() => {
        const storedWishlist = localStorage.getItem('wishlist');
        return storedWishlist ? JSON.parse(storedWishlist) : [];
    });
    useEffect(() => {
        localStorage.setItem('wishlist', JSON.stringify(wishlist));
    }, [wishlist]);

    const handleWishlistToggle = (productId) => {
        setWishlist((prevWishlist) => {
            const updatedWishlist = prevWishlist.includes(productId)
                ? prevWishlist.filter(id => id !== productId)
                : [...prevWishlist, productId];
            localStorage.setItem('wishlist', JSON.stringify(updatedWishlist));
            return updatedWishlist;
        });
    };
    useEffect(() => {
        const allViews = JSON.parse(localStorage.getItem("TotalViewedProducts")) || [];
        const recentViews = JSON.parse(localStorage.getItem("recentlyViewed")) || [];

        const body = {
            totalViewedProductIds: allViews,
            lastFiveViewedProductIds: recentViews
        }

        getSuggestionProducts(body).then((res) => {
            setData(res.data.suggestedProducts)
        }).catch((err) => {
            throw err;
        })
    }, [])
    return (
        <>
            <h3 className='more-suggestion'>More Products For You</h3>
            <Carousel responsive={responsive} containerClass="carousel-container">
                {/* <div className="season-product-holder"> */}
                {data.map((product) => (
                    <div className="season-product-suggest" key={product._id}>
                        <Link to={`/product/${product._id}`}>
                            <div className="product-img-wrapper-suggest">
                                <div className="season-product-img-suggest">
                                    <img src={product.imageUrl} alt={product.productName} />
                                    <div
                                        className="add-to-cart"
                                        onClick={(e) => {
                                            e.preventDefault();
                                            e.stopPropagation();
                                            handleWishlistToggle(product._id);
                                        }}
                                    >
                                        {wishlist.includes(product._id) ? (
                                            <AiFillHeart
                                                style={{
                                                    color: '#f76363',
                                                    fontSize: '15px',
                                                    cursor: 'pointer'
                                                }}
                                            />
                                        ) : (
                                            <AiOutlineHeart
                                                style={{
                                                    color: '#000',
                                                    fontSize: '15px',
                                                    cursor: 'pointer'
                                                }}
                                            />
                                        )}
                                    </div>
                                </div>
                            </div>
                        </Link>
                        <div className="season-product-content-suggest">
                            <Link style={{ textDecoration: "none" }} to={`/product/${product._id}`}><p className="pd-name">{product.productName}</p></Link>
                            <div classNmae='pd-price-holder-sugs'>
                                <p className="pd-dst-price"><span className='sugs-dist-price'>₹{product.discountedPrice}</span> <span className='sugs-org-price'><del>₹{product.originalPrice}</del></span> <span className='sugs-price-off'>({((product.originalPrice - product.discountedPrice) / product.originalPrice * 100).toFixed(0)}% Off)</span></p>
                            </div>
                        </div>
                    </div>
                ))}
                {/* </div> */}
            </Carousel>
        </>
    )
}

export default SuggestElements