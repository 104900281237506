import './App.css';
import React, { useEffect, useState, lazy, Suspense } from 'react';
import { BrowserRouter as Router, Routes, Route, useLocation, json } from 'react-router-dom';
import Home from './pages/Home/Home';
import Product from './pages/Product/Product';
import ProdDesc from './pages/ProdDescPage/ProdDesc';
import Cart from './pages/Cart/Cart';
import Information from './pages/Information/Information';
import Checkout from './pages/Checkout/Checkout';
import { CheckoutIdContext } from './helper/Context';
import Success from './pages/Success/Success';
import Exchange from './pages/Exchange/Exchange';
import PartnerSignUp from './pages/PartnerSignUp/PartnerSignUp';
import Dashboard from './pages/Dashboard/Dashboard';
import WishList from './pages/WishList/WishList';
import PathTracker from './component/PathTracker/PathTracker';
import About from './pages/About/About';
import PrivacyPolicy from './pages/PrivacyPolicy/PrivacyPolicy';
import ExchangePolicy from './pages/ExchangePolicy/ExchangePolicy';
import ShippingPolicy from './pages/ShippingPolicy/ShippingPolicy';
import WhatsAppConct from './component/WhatsApp/WhatsAppConct';
import MerchandiseDB from './pages/MerchandiseDB/MerchandiseDB';

const useQuery = () => {
  return new URLSearchParams(useLocation().search);
};

// const Home = lazy(()=> import("./pages/Home/Home"));
// const Product = lazy(()=> import("./pages/Product/Product"));
// const ProdDesc = lazy(()=> import("./pages/ProdDescPage/ProdDesc"));
// const Cart = lazy(()=> import("./pages/Cart/Cart"));
// const Information = lazy(()=> import("./pages/Information/Information"));
// const Checkout = lazy(()=> import("./pages/Checkout/Checkout"));
// const Success = lazy(()=> import("./pages/Success/Success"));
// const Exchange = lazy(()=> import("./pages/Exchange/Exchange"));
// const PartnerSignUp = lazy(()=> import("./pages/PartnerSignUp/PartnerSignUp"));
// const Dashboard = lazy(()=> import("./pages/Dashboard/Dashboard"));
// const WishList = lazy(()=> import("./pages/WishList/WishList"));
// const PathTracker = lazy(()=> import("./component/PathTracker/PathTracker"));
// const About = lazy(()=> import("./pages/About/About"));
// const PrivacyPolicy = lazy(()=> import("./pages/PrivacyPolicy/PrivacyPolicy"));
// const ExchangePolicy = lazy(()=> import("./pages/ExchangePolicy/ExchangePolicy"));
// const ShippingPolicy = lazy(()=> import("./pages/ShippingPolicy/ShippingPolicy"));


const App = () => {
  const [checkoutProdId, setCheckoutProdId] = useState(false);
  const [referralId, setReferralId] = useState(null);

  const useQueryEffect = () => {
    const query = useQuery();
    useEffect(() => {
      const ref = query.get('ref');
      if (ref) {
        sessionStorage.setItem('referralId', ref);
        setReferralId(ref);
      }
    }, [query]);
  };
  return (
    <CheckoutIdContext.Provider value={{ checkoutProdId, setCheckoutProdId }}>
      <Router>
        <ReferralHandler />
        <PathTracker />
        <div>
          <WhatsAppConct />
          <Routes>
            <Route path="/" element={<Home />} />
            <Route path='/products/:type/:category/:series' element={<Product />} />
            <Route path='/product/:id' element={<ProdDesc />} />
            <Route path='/cart' element={<Cart />} />
            <Route path='/your-info' element={<Information />} />
            <Route path='/checkout' element={<Checkout />} />
            <Route path='/success/payment' element={<Success />} />
            <Route path='/exchange' element={<Exchange />} />
            <Route path='/be/a/partner' element={<PartnerSignUp />} />
            <Route path='/affiliate/dashboard' element={<Dashboard />} />
            <Route path='/wishlist' element={<WishList />} />
            <Route path='/about' element={<About />} />
            <Route path='/privacy/policy' element={<PrivacyPolicy />} />
            <Route path='/exchange/policy' element={<ExchangePolicy />} />
            <Route path='/shipping/policy' element={<ShippingPolicy />} />
            <Route path='/merchandise-dashboard' element={<MerchandiseDB />} />
          </Routes>
        </div>
      </Router>
    </CheckoutIdContext.Provider>
  );
};

const ReferralHandler = () => {
  const query = useQuery();
  useEffect(() => {
    const ref = query.get('ref');
    if (ref) {
      sessionStorage.setItem('referralId', ref);
    }
  }, [query]);

  return null;
};

export default App;
