import React, { useEffect, useState } from 'react';

const GradientDiv = ({ totalSoldTrack }) => {
  const [gradientStyle, setGradientStyle] = useState({});

  const gradientSets = [
    // Set 1: Soft blue, teal, and lavender
    {
      background: 'linear-gradient(135deg, #ADD8E6, #20B2AA, #9370DB)' // lightblue, lightseagreen, medium purple
    },
    // Set 2: Cool cyan, soft purple, and steel blue
    {
      background: 'linear-gradient(90deg, #00CED1, #9370DB, #4682B4)' // dark cyan, medium purple, steel blue
    },
    // Set 3: Turquoise, sky blue, and medium violet
    {
      background: 'linear-gradient(120deg, #40E0D0, #00BFFF, #DDA0DD)' // turquoise, deepskyblue, plum
    },
    // Set 4: Mint, aquamarine, and periwinkle
    {
      background: 'linear-gradient(160deg, #98FF98, #7FFFD4, #CCCCFF)' // darker mint, aquamarine, periwinkle
    }
  ];

  const getRandomGradient = () => {
    // Randomly select one of the gradient sets
    const randomIndex = Math.floor(Math.random() * gradientSets.length);
    return gradientSets[randomIndex];
  };

  useEffect(() => {
    // Set the gradient style randomly when the component mounts
    setGradientStyle(getRandomGradient());
  }, []);

  return (
    <div
      style={{
        backgroundColor:"#b0ffc9",
        borderRadius: '0px',
        color: '#000',
        textAlign: 'center',
        fontSize: '13px',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        height: "6vh",
        fontWeight: "400"
      }}
    >
      Hurry up! Total {totalSoldTrack} pieces sold in last 7 days
    </div>
  );
};

export default GradientDiv;
