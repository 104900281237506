import React, { lazy, Suspense, useState, useRef, useEffect } from 'react';
import Navbar from '../../component/Navbar/Navbar';
import Hero from '../../component/Hero/Hero';
import TopCategories from '../../component/TopCategories/TopCategories';
import TopProducts from '../../component/TopProducts/TopProducts';
import TopProductMobile from '../../component/TopProductMobile/TopProductMobile';
import NavTop from '../../component/NavTop/NavTop';
import Loader from '../../component/Loader/Loader';

// Lazy load less critical components
const AboutGrid = lazy(() => import('../../component/AboutGrid/AboutGrid'));
const SuggestProducts = lazy(() => import('../../component/SuggestProducts/SuggestProducts'));
const Footer = lazy(() => import('../../component/Footer/Footer'));

const Home = () => {
  const [renderRestOfPage, setRenderRestOfPage] = useState(false);
  const [loading, setLoading] = useState(true);

  const bannerRef = useRef(null);
  useEffect(() => {

    // Check if the page is fully loaded
    const handlePageLoad = () => {
      setLoading(false); // Page fully loaded, hide the loader
    };

    if (document.readyState === 'complete') {
      handlePageLoad(); // If already loaded
    } else {
      window.addEventListener('load', handlePageLoad); // Wait for full load
    }

    return () => {
      window.removeEventListener('load', handlePageLoad); // Cleanup load event
    };
  }, []);

// Show loader until loading is complete
if (loading) {
  return <Loader />;
}


return (
  <div>
    <NavTop />
    <Navbar />
    {/* <div style={{ marginTop: '7vh' }} className='top-product-mobile'>
      <TopProductMobile />
    </div> */}
    <div ref={bannerRef} >
      <Hero />
    </div>
        {/* <TopCategories /> */}
        <TopProducts />
        <Suspense fallback={<p>Loading suggestions...</p>}>
          <SuggestProducts />
        </Suspense>
        <Suspense fallback={<p>Loading about section...</p>}>
          <AboutGrid />
        </Suspense>
        <Suspense fallback={<p>Loading footer...</p>}>
          <Footer />
        </Suspense>
  </div>
);
};

export default Home;
