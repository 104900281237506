import React from 'react';
import Navbar from '../../component/Navbar/Navbar';
import NavTop from '../../component/NavTop/NavTop';
import TopProductMobile from '../../component/TopProductMobile/TopProductMobile';

const MerchandiseDB = () => {
    return (
        <>
            <Navbar />
            <NavTop />
            <div className="top-product-mobile">
                <TopProductMobile />
            </div>
        </>
    )
};

export default MerchandiseDB;