import React from 'react';
import { FaStar, FaStarHalfAlt } from 'react-icons/fa';
import { IconContext } from 'react-icons';

const StarRating = ({ rating }) => {
  // Create an array to represent 5 stars
  const stars = Array.from({ length: 5 }, (_, index) => {
    const starValue = index + 1;

    // Logic for full, half, and empty stars
    if (rating >= starValue) {
      return <FaStar key={index} />;
    } else if (rating >= starValue - 0.5) {
      return <FaStarHalfAlt key={index} />;
    } else {
      return <FaStar key={index} style={{ color: '#ddd' }} />;
    }
  });

  return (
    <IconContext.Provider value={{ color: '#ffc107', size: '18px' }}>
      <div style={{ display: 'flex', gap: '5px' }}>
        {stars}
      </div>
    </IconContext.Provider>
  );
};

export default StarRating;
