import React from 'react';
import ImageGallery from 'react-image-gallery';
import 'react-image-gallery/styles/css/image-gallery.css';
import './ImageCarousel.css'; // Custom styles

const ImageCarousel = ({ images }) => {
  // Convert images to the required format for react-image-gallery
  const galleryImages = images.map((imageUrl) => ({
    original: imageUrl,   // The main image
    thumbnail: imageUrl,  // Thumbnail (can be customized)
  }));

  return (
    <div className="imgs-carousel">
      <ImageGallery
        items={galleryImages}
        showPlayButton={false}  // Disable autoplay button
        showFullscreenButton={true}  // Allow fullscreen on click (for zooming)
        showThumbnails={true}  // Thumbnails will show below the main image
        lazyLoad={true}  // Lazy load images for performance
        infinite={true}  // Enable infinite scrolling
        useBrowserFullscreen={false}  // Custom fullscreen mode for zoom
        disableArrowKeys={false}  // Allow arrow key navigation
        additionalClass="custom-gallery"
        autoPlay={true}  // Optional: autoplay the carousel
        slideInterval={3000}  // Set autoplay speed (3 seconds)
        swipeable={true}  // Enable swiping for mobile devices
      />
    </div>
  );
};

export default ImageCarousel;
